import {isAplaRegion, isEmeaRegion} from './authorizationService'

const SAP_VERSION_S4 = 'S4'

export function showS4View(sapVersion) {
    return isEmeaRegion() && sapVersion === SAP_VERSION_S4
}

export function showAfsView(sapVersion) {
    return isAplaRegion() || (isEmeaRegion() && sapVersion === null)
}