import { BackButton } from '../../shared/component/BackButton'
import { Text } from '@nike/eds'

export interface FinanceDetailHeaderProps {
    rsoNumber: String
}

export function ReturnsDetailHeader({rsoNumber}: FinanceDetailHeaderProps) {
    return (
        <title className="eds-spacing--mb-16">
            <BackButton/>
            <Text font="display-4" as="h1">
                Return {rsoNumber}
            </Text>
        </title>
    )
}