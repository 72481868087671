import { useQuery } from '@apollo/client'
import { Text } from '@nike/eds'
import { ReturnsDetailHeader } from './ReturnsDetailHeader'
import { ApolloResult } from '../../shared/component/ApolloResult'
import { GetFinanceViewDocument } from '../../generated/graphql'
import { ReturnsDetailTabGroup } from './ReturnsDetailTabGroup'
import { geoConfig } from '../../config/GeoConfig'
import React, { useState } from 'react'
import { detailTabConfig } from './DetailTabConfig'
import { InfoSnack } from '../../shared/component/InfoSnack'
import { useLocation } from 'react-router-dom'
import { S4ReturnsDetailCard } from './s4/S4ReturnsDetailCard'
import { AfsReturnsDetailCard } from './afs/AfsReturnsDetailCard'
import { showAfsView, showS4View } from '../../shared/datautils/regionView'

export function ReturnsDetail({returnSalesOrderNumber}) {
    const [detailUnitsState, updateDetailUnits] = useState(detailTabConfig.getDetailTab())

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const plantCode = query.get("plantCode");

    const returnSalesOrderQueryResult = useQuery(GetFinanceViewDocument, {
        variables: {
            returnSalesOrderNumber: returnSalesOrderNumber,
            plantCode: plantCode,
            geoAllocation: geoConfig.getGeo()
        }
    })

    const returnSalesOrder = returnSalesOrderQueryResult?.data?.getFinanceView
    const creditingOnProductLevel = returnSalesOrder?.creditingOnProductLevel
    const sapVersion = returnSalesOrder?.sap
    const orderUnits = returnSalesOrder?.units
    const isShowNoUnitsSnack = orderUnits?.expectedUnits === 0 && orderUnits?.receivedUnits === 0
    const isShowDetailTabGroup = !returnSalesOrderQueryResult?.error

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 'var(--eds-space-8)',
            marginBottom: 'var(--eds-space-72)',
        }}>
            <ApolloResult {...returnSalesOrderQueryResult} loadingDataName="return sales order">
                <ReturnsDetailHeader rsoNumber={returnSalesOrderNumber}/>
                {
                    showS4View(sapVersion) &&
                    <S4ReturnsDetailCard detailUnitsState={detailUnitsState} returnSalesOrder={returnSalesOrder} />
                }
                {
                    showAfsView(sapVersion) &&
                    <AfsReturnsDetailCard detailUnitsState={detailUnitsState} returnSalesOrder={returnSalesOrder} />
                }
            </ApolloResult>

            {
                isShowNoUnitsSnack ?
                (
                    <InfoSnack className="eds-spacing--mt-16">
                        <Text font="title-5" as="h5">
                            No units found
                        </Text>
                    </InfoSnack>
                ):
                (
                    isShowDetailTabGroup ?
                        <ReturnsDetailTabGroup returnSalesOrderNumber={returnSalesOrderNumber}
                                               plantCode={plantCode}
                                               creditingOnProductLevel={creditingOnProductLevel}
                                               sapVersion={sapVersion}
                                               updateDetailUnits={updateDetailUnits}/> : <></>
                )
            }
        </div>
    )
}
