import './styles/Layout.css'
import './styles/Labels.css'
import './styles/EdsOverrides.css'

import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Security } from '@okta/okta-react'
import { oktaAuth } from './shared/Okta'
import { createApolloClient } from './config/Apollo'
import { ApolloProvider } from '@apollo/client'
import { toRelativeUrl } from '@okta/okta-auth-js'

import { Routing } from './Routing'
import { Header } from './shared/header/Header'
import { Footer } from './footer/Footer'
import { initPendo } from './plugins/pendo'

const apolloClient = createApolloClient()

function App() {
  const history = useHistory()

  const restoreOriginalUri = useCallback(
    (_oktaAuth: any, originalUri: string) => {
      history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
    },
    [history]
  )

  useEffect(() => {
    oktaAuth.authStateManager.subscribe((authState: { isAuthenticated: boolean }) => {
      if (authState.isAuthenticated) {
        initPendo()
      }
    })

    return () => oktaAuth.authStateManager.unsubscribe()
  }, [])

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ApolloProvider client={apolloClient}>
        <Header />
        <main style={{ padding: 'var(--eds-space-24) var(--eds-space-24) var(--eds-space-12) ' }}>
          <Routing />
        </main>
        <Footer />
      </ApolloProvider>
    </Security>
  )
}

export default App
