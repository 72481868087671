import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type DeleteToleranceInput = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  soldToNbr?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type FinanceDetailUnits = {
  __typename?: 'FinanceDetailUnits';
  expectedAndReceivedNonCGradeUnits?: Maybe<Scalars['Int']>;
  shortageUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageUnits?: Maybe<Scalars['Int']>;
};

export type FinanceViewDocument = {
  __typename?: 'FinanceViewDocument';
  approvalDate?: Maybe<Scalars['String']>;
  billingBlockHeaderCode?: Maybe<Scalars['String']>;
  closureNodeDateTime?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<Scalars['String']>;
  creditingOnProductLevel?: Maybe<Scalars['Boolean']>;
  customerAccountTypeCode?: Maybe<Scalars['String']>;
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>;
  customerRequestedDeliveryDate?: Maybe<Scalars['String']>;
  fullTolerance?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Scalars['String']>;
  plantCode?: Maybe<Scalars['String']>;
  product?: Maybe<Array<Maybe<FinanceViewProduct>>>;
  productItemList?: Maybe<Array<Maybe<FinanceViewProductItem>>>;
  reasonCode?: Maybe<Scalars['String']>;
  receivedNodeDateTime?: Maybe<Scalars['String']>;
  returnSalesOrderNumber?: Maybe<Scalars['String']>;
  salesOrganisationCode?: Maybe<Scalars['String']>;
  sap?: Maybe<Scalars['String']>;
  shipToCode?: Maybe<Scalars['String']>;
  shipToName?: Maybe<Scalars['String']>;
  soldToCode?: Maybe<Scalars['String']>;
  soldToName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  units?: Maybe<FinanceViewUnits>;
};

export type FinanceViewDocumentOverview = {
  __typename?: 'FinanceViewDocumentOverview';
  approvalDate?: Maybe<Scalars['String']>;
  closureNodeDateTime?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<Scalars['String']>;
  creditingOnProductLevel?: Maybe<Scalars['Boolean']>;
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>;
  customerRequestedDeliveryDate?: Maybe<Scalars['String']>;
  fullTolerance?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Scalars['String']>;
  plantCode?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['String']>;
  receivedNodeDateTime?: Maybe<Scalars['String']>;
  returnSalesOrderNumber?: Maybe<Scalars['String']>;
  sap?: Maybe<Scalars['String']>;
  shipToCode?: Maybe<Scalars['String']>;
  shipToName?: Maybe<Scalars['String']>;
  soldToCode?: Maybe<Scalars['String']>;
  soldToName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  units?: Maybe<FinanceViewUnits>;
};

export type FinanceViewDocumentOverviewWrapper = {
  __typename?: 'FinanceViewDocumentOverviewWrapper';
  financeViewDocuments?: Maybe<Array<Maybe<FinanceViewDocumentOverview>>>;
  paging?: Maybe<Paging>;
};

export type FinanceViewDocumentProductItemWrapper = {
  __typename?: 'FinanceViewDocumentProductItemWrapper';
  paging?: Maybe<Paging>;
  productItems?: Maybe<Array<Maybe<FinanceViewProductItem>>>;
};

export type FinanceViewDocumentProductWrapper = {
  __typename?: 'FinanceViewDocumentProductWrapper';
  paging?: Maybe<Paging>;
  products?: Maybe<Array<Maybe<FinanceViewProduct>>>;
};

export type FinanceViewInput = {
  approvalDateFrom?: InputMaybe<Scalars['String']>;
  approvalDateTo?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Int']>;
  orderTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  plantCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reasonCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receivedDateTimeFrom?: InputMaybe<Scalars['String']>;
  receivedDateTimeTo?: InputMaybe<Scalars['String']>;
  returnSalesOrderNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  salesOrganisationCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  shipToCustomers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  size?: InputMaybe<Scalars['Int']>;
  soldToCustomers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sorting?: InputMaybe<Array<InputMaybe<Sorting>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FinanceViewProduct = {
  __typename?: 'FinanceViewProduct';
  productId?: Maybe<Scalars['String']>;
  productUnits?: Maybe<FinanceViewProductUnits>;
  status?: Maybe<Scalars['String']>;
};

export type FinanceViewProductItem = {
  __typename?: 'FinanceViewProductItem';
  productId?: Maybe<Scalars['String']>;
  productUnits?: Maybe<FinanceViewProductUnits>;
  size?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type FinanceViewProductUnits = {
  __typename?: 'FinanceViewProductUnits';
  creditableUnits?: Maybe<Scalars['Int']>;
  expectedAndReceivedNonCGradeUnits?: Maybe<Scalars['Int']>;
  expectedBGradeUnits?: Maybe<Scalars['Int']>;
  expectedCGradeUnits?: Maybe<Scalars['Int']>;
  expectedUnits?: Maybe<Scalars['Int']>;
  missingUnits?: Maybe<Scalars['Int']>;
  nonCreditableUnits?: Maybe<Scalars['Int']>;
  overageUnits?: Maybe<Scalars['Int']>;
  receivedUnits?: Maybe<Scalars['Int']>;
  shortageUnits?: Maybe<Scalars['Int']>;
  totalAGradeUnits?: Maybe<Scalars['Int']>;
  totalBGradeUnits?: Maybe<Scalars['Int']>;
  totalCGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageAGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageBGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageCGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageUnits?: Maybe<Scalars['Int']>;
  unexpectedUnits?: Maybe<Scalars['Int']>;
};

export type FinanceViewUnits = {
  __typename?: 'FinanceViewUnits';
  creditableUnits?: Maybe<Scalars['Int']>;
  expectedAndReceivedNonCGradeUnits?: Maybe<Scalars['Int']>;
  expectedUnits?: Maybe<Scalars['Int']>;
  nonCreditableUnits?: Maybe<Scalars['Int']>;
  overageUnits?: Maybe<Scalars['Int']>;
  receivedUnits?: Maybe<Scalars['Int']>;
  shortageUnits?: Maybe<Scalars['Int']>;
  totalAGradeUnits?: Maybe<Scalars['Int']>;
  totalBGradeUnits?: Maybe<Scalars['Int']>;
  totalCGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageUnits?: Maybe<Scalars['Int']>;
  unexpectedUnits?: Maybe<Scalars['Int']>;
};

export type InputToleranceDocument = {
  exclusion?: InputMaybe<Scalars['String']>;
  fullTolerance?: InputMaybe<Scalars['Boolean']>;
  geoAllocation?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  soldToName?: InputMaybe<Scalars['String']>;
  soldToNbr?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type InventoryDetailUnits = {
  __typename?: 'InventoryDetailUnits';
  expectedAndReceivedNonCGradeUnits?: Maybe<Scalars['Int']>;
  shortageUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageUnits?: Maybe<Scalars['Int']>;
};

export type InventoryViewDocument = {
  __typename?: 'InventoryViewDocument';
  approvalDate?: Maybe<Scalars['String']>;
  billingBlockHeaderCode?: Maybe<Scalars['String']>;
  closureNodeDateTime?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<Scalars['String']>;
  customerAccountTypeCode?: Maybe<Scalars['String']>;
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>;
  customerRequestedDeliveryDate?: Maybe<Scalars['String']>;
  plantCode?: Maybe<Scalars['String']>;
  productList?: Maybe<Array<Maybe<InventoryViewProduct>>>;
  reasonCode?: Maybe<Scalars['String']>;
  receivedNodeDateTime?: Maybe<Scalars['String']>;
  returnSalesOrderNumber?: Maybe<Scalars['String']>;
  salesOrganisationCode?: Maybe<Scalars['String']>;
  shipToCode?: Maybe<Scalars['String']>;
  shipToName?: Maybe<Scalars['String']>;
  soldToCode?: Maybe<Scalars['String']>;
  soldToName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  units?: Maybe<InventoryViewUnits>;
};

export type InventoryViewDocumentProductItemWrapper = {
  __typename?: 'InventoryViewDocumentProductItemWrapper';
  paging?: Maybe<Paging>;
  productItems?: Maybe<Array<Maybe<InventoryViewProductItem>>>;
};

export type InventoryViewDocumentProductWrapper = {
  __typename?: 'InventoryViewDocumentProductWrapper';
  paging?: Maybe<Paging>;
  products?: Maybe<Array<Maybe<InventoryViewProduct>>>;
};

export type InventoryViewProduct = {
  __typename?: 'InventoryViewProduct';
  productId?: Maybe<Scalars['String']>;
  productItemList?: Maybe<Array<Maybe<InventoryViewProductItem>>>;
  productUnits?: Maybe<InventoryViewProductUnits>;
  status?: Maybe<Scalars['String']>;
};

export type InventoryViewProductItem = {
  __typename?: 'InventoryViewProductItem';
  productId?: Maybe<Scalars['String']>;
  productUnits?: Maybe<InventoryViewProductUnits>;
  size?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type InventoryViewProductUnits = {
  __typename?: 'InventoryViewProductUnits';
  expectedAndReceivedNonCGradeUnits?: Maybe<Scalars['Int']>;
  expectedBGradeUnits?: Maybe<Scalars['Int']>;
  expectedCGradeUnits?: Maybe<Scalars['Int']>;
  expectedUnits?: Maybe<Scalars['Int']>;
  missingUnits?: Maybe<Scalars['Int']>;
  overageUnits?: Maybe<Scalars['Int']>;
  receivedUnits?: Maybe<Scalars['Int']>;
  shortageUnits?: Maybe<Scalars['Int']>;
  totalAGradeUnits?: Maybe<Scalars['Int']>;
  totalBGradeUnits?: Maybe<Scalars['Int']>;
  totalCGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageAGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageBGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageCGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageUnits?: Maybe<Scalars['Int']>;
  unexpectedUnits?: Maybe<Scalars['Int']>;
};

export type InventoryViewUnits = {
  __typename?: 'InventoryViewUnits';
  expectedAndReceivedNonCGradeUnits?: Maybe<Scalars['Int']>;
  expectedUnits?: Maybe<Scalars['Int']>;
  overageUnits?: Maybe<Scalars['Int']>;
  receivedUnits?: Maybe<Scalars['Int']>;
  shortageUnits?: Maybe<Scalars['Int']>;
  totalAGradeUnits?: Maybe<Scalars['Int']>;
  totalBGradeUnits?: Maybe<Scalars['Int']>;
  totalCGradeUnits?: Maybe<Scalars['Int']>;
  unexpectedAndOverageUnits?: Maybe<Scalars['Int']>;
  unexpectedUnits?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteToleranceConfig?: Maybe<Scalars['String']>;
  insertToleranceConfig?: Maybe<Scalars['String']>;
  updateToleranceConfig?: Maybe<Scalars['String']>;
};


export type MutationDeleteToleranceConfigArgs = {
  deleteToleranceInput?: InputMaybe<DeleteToleranceInput>;
  geoAllocation?: InputMaybe<Scalars['String']>;
};


export type MutationInsertToleranceConfigArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  toleranceConfig?: InputMaybe<InputToleranceDocument>;
};


export type MutationUpdateToleranceConfigArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  updateToleranceInput?: InputMaybe<UpdateToleranceInput>;
};

export type Paging = {
  __typename?: 'Paging';
  totalPages?: Maybe<Scalars['Int']>;
  totalResources?: Maybe<Scalars['Int']>;
};

export type PlantCodeItem = {
  __typename?: 'PlantCodeItem';
  plantCode?: Maybe<Scalars['String']>;
};

export type ProductsViewInput = {
  from?: InputMaybe<Scalars['Int']>;
  plantCode?: InputMaybe<Scalars['String']>;
  returnSalesOrderNumber?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<Array<InputMaybe<Sorting>>>;
};

export type Query = {
  __typename?: 'Query';
  getFinanceDetailUnits?: Maybe<FinanceDetailUnits>;
  getFinanceReturnSalesOrderProductItems?: Maybe<FinanceViewDocumentProductItemWrapper>;
  getFinanceReturnSalesOrderProducts?: Maybe<FinanceViewDocumentProductWrapper>;
  getFinanceView?: Maybe<FinanceViewDocument>;
  getInventoryDetailUnits?: Maybe<InventoryDetailUnits>;
  getReturnSalesOrder?: Maybe<InventoryViewDocument>;
  getReturnSalesOrderProductItems?: Maybe<InventoryViewDocumentProductItemWrapper>;
  getReturnSalesOrderProducts?: Maybe<InventoryViewDocumentProductWrapper>;
  searchFinanceView?: Maybe<FinanceViewDocumentOverviewWrapper>;
  searchPlantCodes?: Maybe<Array<Maybe<PlantCodeItem>>>;
  searchTolerances?: Maybe<ToleranceDocumentWrapper>;
};


export type QueryGetFinanceDetailUnitsArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  plantCode?: InputMaybe<Scalars['String']>;
  returnSalesOrderNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetFinanceReturnSalesOrderProductItemsArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  productsViewInput?: InputMaybe<ProductsViewInput>;
};


export type QueryGetFinanceReturnSalesOrderProductsArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  productsViewInput?: InputMaybe<ProductsViewInput>;
};


export type QueryGetFinanceViewArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  plantCode?: InputMaybe<Scalars['String']>;
  returnSalesOrderNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetInventoryDetailUnitsArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  plantCode?: InputMaybe<Scalars['String']>;
  returnSalesOrderNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetReturnSalesOrderArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  plantCode?: InputMaybe<Scalars['String']>;
  returnSalesOrderNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetReturnSalesOrderProductItemsArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  productsViewInput?: InputMaybe<ProductsViewInput>;
};


export type QueryGetReturnSalesOrderProductsArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  productsViewInput?: InputMaybe<ProductsViewInput>;
};


export type QuerySearchFinanceViewArgs = {
  financeViewInput?: InputMaybe<FinanceViewInput>;
  geoAllocation?: InputMaybe<Scalars['String']>;
};


export type QuerySearchPlantCodesArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
};


export type QuerySearchTolerancesArgs = {
  geoAllocation?: InputMaybe<Scalars['String']>;
  searchToleranceInput?: InputMaybe<SearchToleranceInput>;
};

export type SearchToleranceInput = {
  from?: InputMaybe<Scalars['Int']>;
  geoAllocation?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  soldToNumber?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Array<InputMaybe<Sorting>>>;
};

export type Sorting = {
  desc?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ToleranceDocument = {
  __typename?: 'ToleranceDocument';
  exclusion?: Maybe<Scalars['String']>;
  fullTolerance?: Maybe<Scalars['Boolean']>;
  geoAllocation?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  soldToName?: Maybe<Scalars['String']>;
  soldToNbr?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type ToleranceDocumentWrapper = {
  __typename?: 'ToleranceDocumentWrapper';
  paging?: Maybe<Paging>;
  toleranceDocuments?: Maybe<Array<Maybe<ToleranceDocument>>>;
};

export type UpdateToleranceInput = {
  fullTolerance?: InputMaybe<Scalars['Boolean']>;
  geoAllocation?: InputMaybe<Scalars['String']>;
  soldToNbr?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type SearchPlantCodesQueryVariables = Exact<{
  geoAllocation?: InputMaybe<Scalars['String']>;
}>;


export type SearchPlantCodesQuery = { __typename?: 'Query', searchPlantCodes?: Array<{ __typename?: 'PlantCodeItem', plantCode?: string | null } | null> | null };

export type GetFinanceViewQueryVariables = Exact<{
  returnSalesOrderNumber: Scalars['String'];
  plantCode: Scalars['String'];
  geoAllocation: Scalars['String'];
}>;


export type GetFinanceViewQuery = { __typename?: 'Query', getFinanceView?: { __typename?: 'FinanceViewDocument', returnSalesOrderNumber?: string | null, plantCode?: string | null, soldToCode?: string | null, soldToName?: string | null, shipToCode?: string | null, shipToName?: string | null, reasonCode?: string | null, orderType?: string | null, createdByUser?: string | null, customerPurchaseOrderNumber?: string | null, customerRequestedDeliveryDate?: string | null, approvalDate?: string | null, receivedNodeDateTime?: string | null, closureNodeDateTime?: string | null, billingBlockHeaderCode?: string | null, customerAccountTypeCode?: string | null, salesOrganisationCode?: string | null, fullTolerance?: boolean | null, creditingOnProductLevel?: boolean | null, status?: string | null, sap?: string | null, units?: { __typename?: 'FinanceViewUnits', expectedUnits?: number | null, receivedUnits?: number | null, creditableUnits?: number | null, nonCreditableUnits?: number | null, expectedAndReceivedNonCGradeUnits?: number | null, totalAGradeUnits?: number | null, totalBGradeUnits?: number | null, totalCGradeUnits?: number | null, unexpectedUnits?: number | null, overageUnits?: number | null, shortageUnits?: number | null, unexpectedAndOverageUnits?: number | null } | null } | null };

export type GetFinanceReturnSalesOrderProductsQueryVariables = Exact<{
  productsViewInput?: InputMaybe<ProductsViewInput>;
  geoAllocation: Scalars['String'];
}>;


export type GetFinanceReturnSalesOrderProductsQuery = { __typename?: 'Query', getFinanceReturnSalesOrderProducts?: { __typename?: 'FinanceViewDocumentProductWrapper', paging?: { __typename?: 'Paging', totalPages?: number | null, totalResources?: number | null } | null, products?: Array<{ __typename?: 'FinanceViewProduct', productId?: string | null, status?: string | null, productUnits?: { __typename?: 'FinanceViewProductUnits', expectedUnits?: number | null, receivedUnits?: number | null, creditableUnits?: number | null, nonCreditableUnits?: number | null, expectedAndReceivedNonCGradeUnits?: number | null, totalAGradeUnits?: number | null, totalBGradeUnits?: number | null, totalCGradeUnits?: number | null, unexpectedUnits?: number | null, overageUnits?: number | null, shortageUnits?: number | null, unexpectedAndOverageUnits?: number | null, expectedBGradeUnits?: number | null, expectedCGradeUnits?: number | null, missingUnits?: number | null, unexpectedAndOverageAGradeUnits?: number | null, unexpectedAndOverageBGradeUnits?: number | null, unexpectedAndOverageCGradeUnits?: number | null } | null } | null> | null } | null };

export type GetFinanceReturnSalesOrderProductItemsQueryVariables = Exact<{
  productsViewInput?: InputMaybe<ProductsViewInput>;
  geoAllocation: Scalars['String'];
}>;


export type GetFinanceReturnSalesOrderProductItemsQuery = { __typename?: 'Query', getFinanceReturnSalesOrderProductItems?: { __typename?: 'FinanceViewDocumentProductItemWrapper', paging?: { __typename?: 'Paging', totalPages?: number | null, totalResources?: number | null } | null, productItems?: Array<{ __typename?: 'FinanceViewProductItem', productId?: string | null, size?: string | null, status?: string | null, productUnits?: { __typename?: 'FinanceViewProductUnits', expectedUnits?: number | null, receivedUnits?: number | null, creditableUnits?: number | null, nonCreditableUnits?: number | null, expectedAndReceivedNonCGradeUnits?: number | null, totalAGradeUnits?: number | null, totalBGradeUnits?: number | null, totalCGradeUnits?: number | null, unexpectedUnits?: number | null, overageUnits?: number | null, shortageUnits?: number | null, unexpectedAndOverageUnits?: number | null, expectedBGradeUnits?: number | null, expectedCGradeUnits?: number | null, missingUnits?: number | null, unexpectedAndOverageAGradeUnits?: number | null, unexpectedAndOverageBGradeUnits?: number | null, unexpectedAndOverageCGradeUnits?: number | null } | null } | null> | null } | null };

export type GetFinanceDetailUnitsQueryVariables = Exact<{
  returnSalesOrderNumber: Scalars['String'];
  plantCode: Scalars['String'];
  geoAllocation: Scalars['String'];
}>;


export type GetFinanceDetailUnitsQuery = { __typename?: 'Query', getFinanceDetailUnits?: { __typename?: 'FinanceDetailUnits', expectedAndReceivedNonCGradeUnits?: number | null, unexpectedAndOverageUnits?: number | null, shortageUnits?: number | null } | null };

export type GetReturnSalesOrderQueryVariables = Exact<{
  returnSalesOrderNumber: Scalars['String'];
  plantCode: Scalars['String'];
  geoAllocation: Scalars['String'];
}>;


export type GetReturnSalesOrderQuery = { __typename?: 'Query', getReturnSalesOrder?: { __typename?: 'InventoryViewDocument', returnSalesOrderNumber?: string | null, plantCode?: string | null, soldToCode?: string | null, soldToName?: string | null, shipToCode?: string | null, shipToName?: string | null, reasonCode?: string | null, createdByUser?: string | null, customerPurchaseOrderNumber?: string | null, customerRequestedDeliveryDate?: string | null, approvalDate?: string | null, receivedNodeDateTime?: string | null, closureNodeDateTime?: string | null, billingBlockHeaderCode?: string | null, customerAccountTypeCode?: string | null, salesOrganisationCode?: string | null, status?: string | null, units?: { __typename?: 'InventoryViewUnits', expectedUnits?: number | null, receivedUnits?: number | null, expectedAndReceivedNonCGradeUnits?: number | null, totalAGradeUnits?: number | null, totalBGradeUnits?: number | null, totalCGradeUnits?: number | null, unexpectedUnits?: number | null, overageUnits?: number | null, shortageUnits?: number | null, unexpectedAndOverageUnits?: number | null } | null } | null };

export type GetReturnSalesOrderProductsQueryVariables = Exact<{
  productsViewInput?: InputMaybe<ProductsViewInput>;
  geoAllocation: Scalars['String'];
}>;


export type GetReturnSalesOrderProductsQuery = { __typename?: 'Query', getReturnSalesOrderProducts?: { __typename?: 'InventoryViewDocumentProductWrapper', paging?: { __typename?: 'Paging', totalPages?: number | null, totalResources?: number | null } | null, products?: Array<{ __typename?: 'InventoryViewProduct', productId?: string | null, status?: string | null, productUnits?: { __typename?: 'InventoryViewProductUnits', expectedUnits?: number | null, receivedUnits?: number | null, expectedAndReceivedNonCGradeUnits?: number | null, totalAGradeUnits?: number | null, totalBGradeUnits?: number | null, totalCGradeUnits?: number | null, unexpectedUnits?: number | null, overageUnits?: number | null, shortageUnits?: number | null, unexpectedAndOverageUnits?: number | null, expectedBGradeUnits?: number | null, expectedCGradeUnits?: number | null, missingUnits?: number | null, unexpectedAndOverageAGradeUnits?: number | null, unexpectedAndOverageBGradeUnits?: number | null, unexpectedAndOverageCGradeUnits?: number | null } | null } | null> | null } | null };

export type GetReturnSalesOrderProductItemsQueryVariables = Exact<{
  productsViewInput?: InputMaybe<ProductsViewInput>;
  geoAllocation: Scalars['String'];
}>;


export type GetReturnSalesOrderProductItemsQuery = { __typename?: 'Query', getReturnSalesOrderProductItems?: { __typename?: 'InventoryViewDocumentProductItemWrapper', paging?: { __typename?: 'Paging', totalPages?: number | null, totalResources?: number | null } | null, productItems?: Array<{ __typename?: 'InventoryViewProductItem', productId?: string | null, size?: string | null, status?: string | null, productUnits?: { __typename?: 'InventoryViewProductUnits', expectedUnits?: number | null, receivedUnits?: number | null, expectedAndReceivedNonCGradeUnits?: number | null, totalAGradeUnits?: number | null, totalBGradeUnits?: number | null, totalCGradeUnits?: number | null, unexpectedUnits?: number | null, overageUnits?: number | null, shortageUnits?: number | null, unexpectedAndOverageUnits?: number | null, expectedBGradeUnits?: number | null, expectedCGradeUnits?: number | null, missingUnits?: number | null, unexpectedAndOverageAGradeUnits?: number | null, unexpectedAndOverageBGradeUnits?: number | null, unexpectedAndOverageCGradeUnits?: number | null } | null } | null> | null } | null };

export type GetInventoryDetailUnitsQueryVariables = Exact<{
  returnSalesOrderNumber: Scalars['String'];
  plantCode: Scalars['String'];
  geoAllocation: Scalars['String'];
}>;


export type GetInventoryDetailUnitsQuery = { __typename?: 'Query', getInventoryDetailUnits?: { __typename?: 'InventoryDetailUnits', expectedAndReceivedNonCGradeUnits?: number | null, unexpectedAndOverageUnits?: number | null, shortageUnits?: number | null } | null };

export type SearchFinanceViewQueryVariables = Exact<{
  financeViewInput?: InputMaybe<FinanceViewInput>;
  geoAllocation: Scalars['String'];
}>;


export type SearchFinanceViewQuery = { __typename?: 'Query', searchFinanceView?: { __typename?: 'FinanceViewDocumentOverviewWrapper', paging?: { __typename?: 'Paging', totalPages?: number | null, totalResources?: number | null } | null, financeViewDocuments?: Array<{ __typename?: 'FinanceViewDocumentOverview', returnSalesOrderNumber?: string | null, plantCode?: string | null, soldToCode?: string | null, soldToName?: string | null, shipToCode?: string | null, shipToName?: string | null, createdByUser?: string | null, reasonCode?: string | null, orderType?: string | null, customerPurchaseOrderNumber?: string | null, customerRequestedDeliveryDate?: string | null, approvalDate?: string | null, receivedNodeDateTime?: string | null, closureNodeDateTime?: string | null, status?: string | null, sap?: string | null, fullTolerance?: boolean | null, creditingOnProductLevel?: boolean | null, units?: { __typename?: 'FinanceViewUnits', expectedUnits?: number | null, receivedUnits?: number | null, expectedAndReceivedNonCGradeUnits?: number | null, totalAGradeUnits?: number | null, totalBGradeUnits?: number | null, totalCGradeUnits?: number | null, unexpectedUnits?: number | null, overageUnits?: number | null, shortageUnits?: number | null, unexpectedAndOverageUnits?: number | null } | null } | null> | null } | null };

export type DeleteToleranceConfigMutationVariables = Exact<{
  deleteToleranceInput?: InputMaybe<DeleteToleranceInput>;
  geoAllocation?: InputMaybe<Scalars['String']>;
}>;


export type DeleteToleranceConfigMutation = { __typename?: 'Mutation', deleteToleranceConfig?: string | null };

export type UpdateToleranceConfigMutationVariables = Exact<{
  updateToleranceInput?: InputMaybe<UpdateToleranceInput>;
  geoAllocation?: InputMaybe<Scalars['String']>;
}>;


export type UpdateToleranceConfigMutation = { __typename?: 'Mutation', updateToleranceConfig?: string | null };

export type InsertToleranceConfigMutationVariables = Exact<{
  toleranceConfig?: InputMaybe<InputToleranceDocument>;
  geoAllocation?: InputMaybe<Scalars['String']>;
}>;


export type InsertToleranceConfigMutation = { __typename?: 'Mutation', insertToleranceConfig?: string | null };

export type SearchTolerancesQueryVariables = Exact<{
  searchToleranceInput?: InputMaybe<SearchToleranceInput>;
  geoAllocation?: InputMaybe<Scalars['String']>;
}>;


export type SearchTolerancesQuery = { __typename?: 'Query', searchTolerances?: { __typename?: 'ToleranceDocumentWrapper', paging?: { __typename?: 'Paging', totalPages?: number | null, totalResources?: number | null } | null, toleranceDocuments?: Array<{ __typename?: 'ToleranceDocument', soldToNbr?: string | null, soldToName?: string | null, group?: string | null, fullTolerance?: boolean | null, exclusion?: string | null, updatedAt?: string | null, updatedBy?: string | null, geoAllocation?: string | null } | null> | null } | null };


export const SearchPlantCodesDocument = gql`
    query searchPlantCodes($geoAllocation: String) {
  searchPlantCodes(geoAllocation: $geoAllocation) {
    plantCode
  }
}
    `;

/**
 * __useSearchPlantCodesQuery__
 *
 * To run a query within a React component, call `useSearchPlantCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlantCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlantCodesQuery({
 *   variables: {
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useSearchPlantCodesQuery(baseOptions?: Apollo.QueryHookOptions<SearchPlantCodesQuery, SearchPlantCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPlantCodesQuery, SearchPlantCodesQueryVariables>(SearchPlantCodesDocument, options);
      }
export function useSearchPlantCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPlantCodesQuery, SearchPlantCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPlantCodesQuery, SearchPlantCodesQueryVariables>(SearchPlantCodesDocument, options);
        }
export type SearchPlantCodesQueryHookResult = ReturnType<typeof useSearchPlantCodesQuery>;
export type SearchPlantCodesLazyQueryHookResult = ReturnType<typeof useSearchPlantCodesLazyQuery>;
export type SearchPlantCodesQueryResult = Apollo.QueryResult<SearchPlantCodesQuery, SearchPlantCodesQueryVariables>;
export const GetFinanceViewDocument = gql`
    query getFinanceView($returnSalesOrderNumber: String!, $plantCode: String!, $geoAllocation: String!) {
  getFinanceView(
    returnSalesOrderNumber: $returnSalesOrderNumber
    plantCode: $plantCode
    geoAllocation: $geoAllocation
  ) {
    returnSalesOrderNumber
    plantCode
    soldToCode
    soldToName
    shipToCode
    shipToName
    reasonCode
    orderType
    createdByUser
    customerPurchaseOrderNumber
    customerRequestedDeliveryDate
    approvalDate
    receivedNodeDateTime
    closureNodeDateTime
    billingBlockHeaderCode
    customerAccountTypeCode
    salesOrganisationCode
    fullTolerance
    creditingOnProductLevel
    status
    sap
    units {
      expectedUnits
      receivedUnits
      creditableUnits
      nonCreditableUnits
      expectedAndReceivedNonCGradeUnits
      totalAGradeUnits
      totalBGradeUnits
      totalCGradeUnits
      unexpectedUnits
      overageUnits
      shortageUnits
      unexpectedAndOverageUnits
    }
  }
}
    `;

/**
 * __useGetFinanceViewQuery__
 *
 * To run a query within a React component, call `useGetFinanceViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinanceViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinanceViewQuery({
 *   variables: {
 *      returnSalesOrderNumber: // value for 'returnSalesOrderNumber'
 *      plantCode: // value for 'plantCode'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetFinanceViewQuery(baseOptions: Apollo.QueryHookOptions<GetFinanceViewQuery, GetFinanceViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinanceViewQuery, GetFinanceViewQueryVariables>(GetFinanceViewDocument, options);
      }
export function useGetFinanceViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinanceViewQuery, GetFinanceViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinanceViewQuery, GetFinanceViewQueryVariables>(GetFinanceViewDocument, options);
        }
export type GetFinanceViewQueryHookResult = ReturnType<typeof useGetFinanceViewQuery>;
export type GetFinanceViewLazyQueryHookResult = ReturnType<typeof useGetFinanceViewLazyQuery>;
export type GetFinanceViewQueryResult = Apollo.QueryResult<GetFinanceViewQuery, GetFinanceViewQueryVariables>;
export const GetFinanceReturnSalesOrderProductsDocument = gql`
    query getFinanceReturnSalesOrderProducts($productsViewInput: ProductsViewInput, $geoAllocation: String!) {
  getFinanceReturnSalesOrderProducts(
    productsViewInput: $productsViewInput
    geoAllocation: $geoAllocation
  ) {
    paging {
      totalPages
      totalResources
    }
    products {
      productId
      status
      productUnits {
        expectedUnits
        receivedUnits
        creditableUnits
        nonCreditableUnits
        expectedAndReceivedNonCGradeUnits
        totalAGradeUnits
        totalBGradeUnits
        totalCGradeUnits
        unexpectedUnits
        overageUnits
        shortageUnits
        unexpectedAndOverageUnits
        expectedBGradeUnits
        expectedCGradeUnits
        missingUnits
        unexpectedAndOverageAGradeUnits
        unexpectedAndOverageBGradeUnits
        unexpectedAndOverageCGradeUnits
      }
    }
  }
}
    `;

/**
 * __useGetFinanceReturnSalesOrderProductsQuery__
 *
 * To run a query within a React component, call `useGetFinanceReturnSalesOrderProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinanceReturnSalesOrderProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinanceReturnSalesOrderProductsQuery({
 *   variables: {
 *      productsViewInput: // value for 'productsViewInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetFinanceReturnSalesOrderProductsQuery(baseOptions: Apollo.QueryHookOptions<GetFinanceReturnSalesOrderProductsQuery, GetFinanceReturnSalesOrderProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinanceReturnSalesOrderProductsQuery, GetFinanceReturnSalesOrderProductsQueryVariables>(GetFinanceReturnSalesOrderProductsDocument, options);
      }
export function useGetFinanceReturnSalesOrderProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinanceReturnSalesOrderProductsQuery, GetFinanceReturnSalesOrderProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinanceReturnSalesOrderProductsQuery, GetFinanceReturnSalesOrderProductsQueryVariables>(GetFinanceReturnSalesOrderProductsDocument, options);
        }
export type GetFinanceReturnSalesOrderProductsQueryHookResult = ReturnType<typeof useGetFinanceReturnSalesOrderProductsQuery>;
export type GetFinanceReturnSalesOrderProductsLazyQueryHookResult = ReturnType<typeof useGetFinanceReturnSalesOrderProductsLazyQuery>;
export type GetFinanceReturnSalesOrderProductsQueryResult = Apollo.QueryResult<GetFinanceReturnSalesOrderProductsQuery, GetFinanceReturnSalesOrderProductsQueryVariables>;
export const GetFinanceReturnSalesOrderProductItemsDocument = gql`
    query getFinanceReturnSalesOrderProductItems($productsViewInput: ProductsViewInput, $geoAllocation: String!) {
  getFinanceReturnSalesOrderProductItems(
    productsViewInput: $productsViewInput
    geoAllocation: $geoAllocation
  ) {
    paging {
      totalPages
      totalResources
    }
    productItems {
      productId
      size
      status
      productUnits {
        expectedUnits
        receivedUnits
        creditableUnits
        nonCreditableUnits
        expectedAndReceivedNonCGradeUnits
        totalAGradeUnits
        totalBGradeUnits
        totalCGradeUnits
        unexpectedUnits
        overageUnits
        shortageUnits
        unexpectedAndOverageUnits
        expectedBGradeUnits
        expectedCGradeUnits
        missingUnits
        unexpectedAndOverageAGradeUnits
        unexpectedAndOverageBGradeUnits
        unexpectedAndOverageCGradeUnits
      }
    }
  }
}
    `;

/**
 * __useGetFinanceReturnSalesOrderProductItemsQuery__
 *
 * To run a query within a React component, call `useGetFinanceReturnSalesOrderProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinanceReturnSalesOrderProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinanceReturnSalesOrderProductItemsQuery({
 *   variables: {
 *      productsViewInput: // value for 'productsViewInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetFinanceReturnSalesOrderProductItemsQuery(baseOptions: Apollo.QueryHookOptions<GetFinanceReturnSalesOrderProductItemsQuery, GetFinanceReturnSalesOrderProductItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinanceReturnSalesOrderProductItemsQuery, GetFinanceReturnSalesOrderProductItemsQueryVariables>(GetFinanceReturnSalesOrderProductItemsDocument, options);
      }
export function useGetFinanceReturnSalesOrderProductItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinanceReturnSalesOrderProductItemsQuery, GetFinanceReturnSalesOrderProductItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinanceReturnSalesOrderProductItemsQuery, GetFinanceReturnSalesOrderProductItemsQueryVariables>(GetFinanceReturnSalesOrderProductItemsDocument, options);
        }
export type GetFinanceReturnSalesOrderProductItemsQueryHookResult = ReturnType<typeof useGetFinanceReturnSalesOrderProductItemsQuery>;
export type GetFinanceReturnSalesOrderProductItemsLazyQueryHookResult = ReturnType<typeof useGetFinanceReturnSalesOrderProductItemsLazyQuery>;
export type GetFinanceReturnSalesOrderProductItemsQueryResult = Apollo.QueryResult<GetFinanceReturnSalesOrderProductItemsQuery, GetFinanceReturnSalesOrderProductItemsQueryVariables>;
export const GetFinanceDetailUnitsDocument = gql`
    query getFinanceDetailUnits($returnSalesOrderNumber: String!, $plantCode: String!, $geoAllocation: String!) {
  getFinanceDetailUnits(
    returnSalesOrderNumber: $returnSalesOrderNumber
    plantCode: $plantCode
    geoAllocation: $geoAllocation
  ) {
    expectedAndReceivedNonCGradeUnits
    unexpectedAndOverageUnits
    shortageUnits
  }
}
    `;

/**
 * __useGetFinanceDetailUnitsQuery__
 *
 * To run a query within a React component, call `useGetFinanceDetailUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinanceDetailUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinanceDetailUnitsQuery({
 *   variables: {
 *      returnSalesOrderNumber: // value for 'returnSalesOrderNumber'
 *      plantCode: // value for 'plantCode'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetFinanceDetailUnitsQuery(baseOptions: Apollo.QueryHookOptions<GetFinanceDetailUnitsQuery, GetFinanceDetailUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinanceDetailUnitsQuery, GetFinanceDetailUnitsQueryVariables>(GetFinanceDetailUnitsDocument, options);
      }
export function useGetFinanceDetailUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinanceDetailUnitsQuery, GetFinanceDetailUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinanceDetailUnitsQuery, GetFinanceDetailUnitsQueryVariables>(GetFinanceDetailUnitsDocument, options);
        }
export type GetFinanceDetailUnitsQueryHookResult = ReturnType<typeof useGetFinanceDetailUnitsQuery>;
export type GetFinanceDetailUnitsLazyQueryHookResult = ReturnType<typeof useGetFinanceDetailUnitsLazyQuery>;
export type GetFinanceDetailUnitsQueryResult = Apollo.QueryResult<GetFinanceDetailUnitsQuery, GetFinanceDetailUnitsQueryVariables>;
export const GetReturnSalesOrderDocument = gql`
    query getReturnSalesOrder($returnSalesOrderNumber: String!, $plantCode: String!, $geoAllocation: String!) {
  getReturnSalesOrder(
    returnSalesOrderNumber: $returnSalesOrderNumber
    plantCode: $plantCode
    geoAllocation: $geoAllocation
  ) {
    returnSalesOrderNumber
    plantCode
    soldToCode
    soldToName
    shipToCode
    shipToName
    reasonCode
    createdByUser
    customerPurchaseOrderNumber
    customerRequestedDeliveryDate
    approvalDate
    receivedNodeDateTime
    closureNodeDateTime
    billingBlockHeaderCode
    customerAccountTypeCode
    salesOrganisationCode
    status
    units {
      expectedUnits
      receivedUnits
      expectedAndReceivedNonCGradeUnits
      totalAGradeUnits
      totalBGradeUnits
      totalCGradeUnits
      unexpectedUnits
      overageUnits
      shortageUnits
      unexpectedAndOverageUnits
    }
  }
}
    `;

/**
 * __useGetReturnSalesOrderQuery__
 *
 * To run a query within a React component, call `useGetReturnSalesOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnSalesOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnSalesOrderQuery({
 *   variables: {
 *      returnSalesOrderNumber: // value for 'returnSalesOrderNumber'
 *      plantCode: // value for 'plantCode'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetReturnSalesOrderQuery(baseOptions: Apollo.QueryHookOptions<GetReturnSalesOrderQuery, GetReturnSalesOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnSalesOrderQuery, GetReturnSalesOrderQueryVariables>(GetReturnSalesOrderDocument, options);
      }
export function useGetReturnSalesOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnSalesOrderQuery, GetReturnSalesOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnSalesOrderQuery, GetReturnSalesOrderQueryVariables>(GetReturnSalesOrderDocument, options);
        }
export type GetReturnSalesOrderQueryHookResult = ReturnType<typeof useGetReturnSalesOrderQuery>;
export type GetReturnSalesOrderLazyQueryHookResult = ReturnType<typeof useGetReturnSalesOrderLazyQuery>;
export type GetReturnSalesOrderQueryResult = Apollo.QueryResult<GetReturnSalesOrderQuery, GetReturnSalesOrderQueryVariables>;
export const GetReturnSalesOrderProductsDocument = gql`
    query getReturnSalesOrderProducts($productsViewInput: ProductsViewInput, $geoAllocation: String!) {
  getReturnSalesOrderProducts(
    productsViewInput: $productsViewInput
    geoAllocation: $geoAllocation
  ) {
    paging {
      totalPages
      totalResources
    }
    products {
      productId
      status
      productUnits {
        expectedUnits
        receivedUnits
        expectedAndReceivedNonCGradeUnits
        totalAGradeUnits
        totalBGradeUnits
        totalCGradeUnits
        unexpectedUnits
        overageUnits
        shortageUnits
        unexpectedAndOverageUnits
        expectedBGradeUnits
        expectedCGradeUnits
        missingUnits
        unexpectedAndOverageAGradeUnits
        unexpectedAndOverageBGradeUnits
        unexpectedAndOverageCGradeUnits
      }
    }
  }
}
    `;

/**
 * __useGetReturnSalesOrderProductsQuery__
 *
 * To run a query within a React component, call `useGetReturnSalesOrderProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnSalesOrderProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnSalesOrderProductsQuery({
 *   variables: {
 *      productsViewInput: // value for 'productsViewInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetReturnSalesOrderProductsQuery(baseOptions: Apollo.QueryHookOptions<GetReturnSalesOrderProductsQuery, GetReturnSalesOrderProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnSalesOrderProductsQuery, GetReturnSalesOrderProductsQueryVariables>(GetReturnSalesOrderProductsDocument, options);
      }
export function useGetReturnSalesOrderProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnSalesOrderProductsQuery, GetReturnSalesOrderProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnSalesOrderProductsQuery, GetReturnSalesOrderProductsQueryVariables>(GetReturnSalesOrderProductsDocument, options);
        }
export type GetReturnSalesOrderProductsQueryHookResult = ReturnType<typeof useGetReturnSalesOrderProductsQuery>;
export type GetReturnSalesOrderProductsLazyQueryHookResult = ReturnType<typeof useGetReturnSalesOrderProductsLazyQuery>;
export type GetReturnSalesOrderProductsQueryResult = Apollo.QueryResult<GetReturnSalesOrderProductsQuery, GetReturnSalesOrderProductsQueryVariables>;
export const GetReturnSalesOrderProductItemsDocument = gql`
    query getReturnSalesOrderProductItems($productsViewInput: ProductsViewInput, $geoAllocation: String!) {
  getReturnSalesOrderProductItems(
    productsViewInput: $productsViewInput
    geoAllocation: $geoAllocation
  ) {
    paging {
      totalPages
      totalResources
    }
    productItems {
      productId
      size
      status
      productUnits {
        expectedUnits
        receivedUnits
        expectedAndReceivedNonCGradeUnits
        totalAGradeUnits
        totalBGradeUnits
        totalCGradeUnits
        unexpectedUnits
        overageUnits
        shortageUnits
        unexpectedAndOverageUnits
        expectedBGradeUnits
        expectedCGradeUnits
        missingUnits
        unexpectedAndOverageAGradeUnits
        unexpectedAndOverageBGradeUnits
        unexpectedAndOverageCGradeUnits
      }
    }
  }
}
    `;

/**
 * __useGetReturnSalesOrderProductItemsQuery__
 *
 * To run a query within a React component, call `useGetReturnSalesOrderProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnSalesOrderProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnSalesOrderProductItemsQuery({
 *   variables: {
 *      productsViewInput: // value for 'productsViewInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetReturnSalesOrderProductItemsQuery(baseOptions: Apollo.QueryHookOptions<GetReturnSalesOrderProductItemsQuery, GetReturnSalesOrderProductItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnSalesOrderProductItemsQuery, GetReturnSalesOrderProductItemsQueryVariables>(GetReturnSalesOrderProductItemsDocument, options);
      }
export function useGetReturnSalesOrderProductItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnSalesOrderProductItemsQuery, GetReturnSalesOrderProductItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnSalesOrderProductItemsQuery, GetReturnSalesOrderProductItemsQueryVariables>(GetReturnSalesOrderProductItemsDocument, options);
        }
export type GetReturnSalesOrderProductItemsQueryHookResult = ReturnType<typeof useGetReturnSalesOrderProductItemsQuery>;
export type GetReturnSalesOrderProductItemsLazyQueryHookResult = ReturnType<typeof useGetReturnSalesOrderProductItemsLazyQuery>;
export type GetReturnSalesOrderProductItemsQueryResult = Apollo.QueryResult<GetReturnSalesOrderProductItemsQuery, GetReturnSalesOrderProductItemsQueryVariables>;
export const GetInventoryDetailUnitsDocument = gql`
    query getInventoryDetailUnits($returnSalesOrderNumber: String!, $plantCode: String!, $geoAllocation: String!) {
  getInventoryDetailUnits(
    returnSalesOrderNumber: $returnSalesOrderNumber
    plantCode: $plantCode
    geoAllocation: $geoAllocation
  ) {
    expectedAndReceivedNonCGradeUnits
    unexpectedAndOverageUnits
    shortageUnits
  }
}
    `;

/**
 * __useGetInventoryDetailUnitsQuery__
 *
 * To run a query within a React component, call `useGetInventoryDetailUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryDetailUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryDetailUnitsQuery({
 *   variables: {
 *      returnSalesOrderNumber: // value for 'returnSalesOrderNumber'
 *      plantCode: // value for 'plantCode'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useGetInventoryDetailUnitsQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryDetailUnitsQuery, GetInventoryDetailUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryDetailUnitsQuery, GetInventoryDetailUnitsQueryVariables>(GetInventoryDetailUnitsDocument, options);
      }
export function useGetInventoryDetailUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryDetailUnitsQuery, GetInventoryDetailUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryDetailUnitsQuery, GetInventoryDetailUnitsQueryVariables>(GetInventoryDetailUnitsDocument, options);
        }
export type GetInventoryDetailUnitsQueryHookResult = ReturnType<typeof useGetInventoryDetailUnitsQuery>;
export type GetInventoryDetailUnitsLazyQueryHookResult = ReturnType<typeof useGetInventoryDetailUnitsLazyQuery>;
export type GetInventoryDetailUnitsQueryResult = Apollo.QueryResult<GetInventoryDetailUnitsQuery, GetInventoryDetailUnitsQueryVariables>;
export const SearchFinanceViewDocument = gql`
    query searchFinanceView($financeViewInput: FinanceViewInput, $geoAllocation: String!) {
  searchFinanceView(
    financeViewInput: $financeViewInput
    geoAllocation: $geoAllocation
  ) {
    paging {
      totalPages
      totalResources
    }
    financeViewDocuments {
      returnSalesOrderNumber
      plantCode
      soldToCode
      soldToName
      shipToCode
      shipToName
      createdByUser
      reasonCode
      orderType
      customerPurchaseOrderNumber
      customerRequestedDeliveryDate
      approvalDate
      receivedNodeDateTime
      closureNodeDateTime
      status
      sap
      fullTolerance
      creditingOnProductLevel
      units {
        expectedUnits
        receivedUnits
        expectedAndReceivedNonCGradeUnits
        totalAGradeUnits
        totalBGradeUnits
        totalCGradeUnits
        unexpectedUnits
        overageUnits
        shortageUnits
        unexpectedAndOverageUnits
      }
    }
  }
}
    `;

/**
 * __useSearchFinanceViewQuery__
 *
 * To run a query within a React component, call `useSearchFinanceViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFinanceViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFinanceViewQuery({
 *   variables: {
 *      financeViewInput: // value for 'financeViewInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useSearchFinanceViewQuery(baseOptions: Apollo.QueryHookOptions<SearchFinanceViewQuery, SearchFinanceViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchFinanceViewQuery, SearchFinanceViewQueryVariables>(SearchFinanceViewDocument, options);
      }
export function useSearchFinanceViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFinanceViewQuery, SearchFinanceViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchFinanceViewQuery, SearchFinanceViewQueryVariables>(SearchFinanceViewDocument, options);
        }
export type SearchFinanceViewQueryHookResult = ReturnType<typeof useSearchFinanceViewQuery>;
export type SearchFinanceViewLazyQueryHookResult = ReturnType<typeof useSearchFinanceViewLazyQuery>;
export type SearchFinanceViewQueryResult = Apollo.QueryResult<SearchFinanceViewQuery, SearchFinanceViewQueryVariables>;
export const DeleteToleranceConfigDocument = gql`
    mutation deleteToleranceConfig($deleteToleranceInput: DeleteToleranceInput, $geoAllocation: String) {
  deleteToleranceConfig(
    deleteToleranceInput: $deleteToleranceInput
    geoAllocation: $geoAllocation
  )
}
    `;
export type DeleteToleranceConfigMutationFn = Apollo.MutationFunction<DeleteToleranceConfigMutation, DeleteToleranceConfigMutationVariables>;

/**
 * __useDeleteToleranceConfigMutation__
 *
 * To run a mutation, you first call `useDeleteToleranceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteToleranceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteToleranceConfigMutation, { data, loading, error }] = useDeleteToleranceConfigMutation({
 *   variables: {
 *      deleteToleranceInput: // value for 'deleteToleranceInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useDeleteToleranceConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteToleranceConfigMutation, DeleteToleranceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteToleranceConfigMutation, DeleteToleranceConfigMutationVariables>(DeleteToleranceConfigDocument, options);
      }
export type DeleteToleranceConfigMutationHookResult = ReturnType<typeof useDeleteToleranceConfigMutation>;
export type DeleteToleranceConfigMutationResult = Apollo.MutationResult<DeleteToleranceConfigMutation>;
export type DeleteToleranceConfigMutationOptions = Apollo.BaseMutationOptions<DeleteToleranceConfigMutation, DeleteToleranceConfigMutationVariables>;
export const UpdateToleranceConfigDocument = gql`
    mutation updateToleranceConfig($updateToleranceInput: UpdateToleranceInput, $geoAllocation: String) {
  updateToleranceConfig(
    updateToleranceInput: $updateToleranceInput
    geoAllocation: $geoAllocation
  )
}
    `;
export type UpdateToleranceConfigMutationFn = Apollo.MutationFunction<UpdateToleranceConfigMutation, UpdateToleranceConfigMutationVariables>;

/**
 * __useUpdateToleranceConfigMutation__
 *
 * To run a mutation, you first call `useUpdateToleranceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateToleranceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateToleranceConfigMutation, { data, loading, error }] = useUpdateToleranceConfigMutation({
 *   variables: {
 *      updateToleranceInput: // value for 'updateToleranceInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useUpdateToleranceConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateToleranceConfigMutation, UpdateToleranceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateToleranceConfigMutation, UpdateToleranceConfigMutationVariables>(UpdateToleranceConfigDocument, options);
      }
export type UpdateToleranceConfigMutationHookResult = ReturnType<typeof useUpdateToleranceConfigMutation>;
export type UpdateToleranceConfigMutationResult = Apollo.MutationResult<UpdateToleranceConfigMutation>;
export type UpdateToleranceConfigMutationOptions = Apollo.BaseMutationOptions<UpdateToleranceConfigMutation, UpdateToleranceConfigMutationVariables>;
export const InsertToleranceConfigDocument = gql`
    mutation insertToleranceConfig($toleranceConfig: InputToleranceDocument, $geoAllocation: String) {
  insertToleranceConfig(
    toleranceConfig: $toleranceConfig
    geoAllocation: $geoAllocation
  )
}
    `;
export type InsertToleranceConfigMutationFn = Apollo.MutationFunction<InsertToleranceConfigMutation, InsertToleranceConfigMutationVariables>;

/**
 * __useInsertToleranceConfigMutation__
 *
 * To run a mutation, you first call `useInsertToleranceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertToleranceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertToleranceConfigMutation, { data, loading, error }] = useInsertToleranceConfigMutation({
 *   variables: {
 *      toleranceConfig: // value for 'toleranceConfig'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useInsertToleranceConfigMutation(baseOptions?: Apollo.MutationHookOptions<InsertToleranceConfigMutation, InsertToleranceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertToleranceConfigMutation, InsertToleranceConfigMutationVariables>(InsertToleranceConfigDocument, options);
      }
export type InsertToleranceConfigMutationHookResult = ReturnType<typeof useInsertToleranceConfigMutation>;
export type InsertToleranceConfigMutationResult = Apollo.MutationResult<InsertToleranceConfigMutation>;
export type InsertToleranceConfigMutationOptions = Apollo.BaseMutationOptions<InsertToleranceConfigMutation, InsertToleranceConfigMutationVariables>;
export const SearchTolerancesDocument = gql`
    query searchTolerances($searchToleranceInput: SearchToleranceInput, $geoAllocation: String) {
  searchTolerances(
    searchToleranceInput: $searchToleranceInput
    geoAllocation: $geoAllocation
  ) {
    paging {
      totalPages
      totalResources
    }
    toleranceDocuments {
      soldToNbr
      soldToName
      group
      fullTolerance
      exclusion
      updatedAt
      updatedBy
      geoAllocation
    }
  }
}
    `;

/**
 * __useSearchTolerancesQuery__
 *
 * To run a query within a React component, call `useSearchTolerancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTolerancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTolerancesQuery({
 *   variables: {
 *      searchToleranceInput: // value for 'searchToleranceInput'
 *      geoAllocation: // value for 'geoAllocation'
 *   },
 * });
 */
export function useSearchTolerancesQuery(baseOptions?: Apollo.QueryHookOptions<SearchTolerancesQuery, SearchTolerancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTolerancesQuery, SearchTolerancesQueryVariables>(SearchTolerancesDocument, options);
      }
export function useSearchTolerancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTolerancesQuery, SearchTolerancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTolerancesQuery, SearchTolerancesQueryVariables>(SearchTolerancesDocument, options);
        }
export type SearchTolerancesQueryHookResult = ReturnType<typeof useSearchTolerancesQuery>;
export type SearchTolerancesLazyQueryHookResult = ReturnType<typeof useSearchTolerancesLazyQuery>;
export type SearchTolerancesQueryResult = Apollo.QueryResult<SearchTolerancesQuery, SearchTolerancesQueryVariables>;