import { oktaAuth } from '../shared/Okta'

export async function initPendo() {
  const user = await oktaAuth.getUser()
  const isLocalHost = window.location.origin.includes('localhost')

  const appEnv = process.env.REACT_APP_ENV
  
  if (!user.email || isLocalHost || !appEnv) return

  // @ts-expect-error pendo initialization
  pendo.initialize({
    visitor: {
      id: `${appEnv}-${user.email}`,
      email: user.email,
      fullName: user.name,
      environment: appEnv,
      zone: user.zoneinfo,
      adGroups: user.groups.join(', '),
    },
    account: {
      id: 'Nike',
      name: 'Nike',
    },
  })
}
