import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import {AppHeader, Icon, Avatar} from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { useOktaAuth } from '@okta/okta-react'
import React, { useMemo } from 'react'
import { extractBooleanFromEnvironmentVariable } from '../datautils/environment'
import {hasToleranceReadAccess, hasReturnsReadAccess, isAplaRegion} from '../datautils/authorizationService'
import {GeoSelect} from './GeoSelect'

function ToolbarLink ({ link, icon }) {
    const active = useRouteMatch(link)

    return (
        <Link
            to={link}
            style={{
                marginRight: 'var(--eds-space-8)',
            }}
        >
            <Icon
                name={icon}
                backgroundShape="circle"
                size="m"
                color={active ? 'white' : 'var(--eds-color-text-secondary)'}
            />
        </Link>
    )
}

function getInitials (name: string) {
    const matchedFirstLetters = name.match(/(\b[A-Z])/g)
    if (!matchedFirstLetters || matchedFirstLetters.length < 2) {
        // Fallback: take first letter
        return name[0]?.toUpperCase()
    }
    return `${matchedFirstLetters.shift()}${matchedFirstLetters.pop()}`
}

export function Header () {
    const { authState } = useOktaAuth()
    const initials = useMemo(() => {
        const name = authState?.idToken?.claims?.name
        return name ? getInitials(name) : '-'
    }, [authState])
    const location = useLocation();

    let pageTitle = 'UNKNOWN'

    switch (location.pathname) {
        case '/returns-overview':
            pageTitle='Returns Finance & Inventory - Overview'
            break
        case location.pathname.match("/returns-detail*")?.input:
            pageTitle='Returns - Details'
            break
        case '/tolerance-overview':
            pageTitle='Tolerance Management'
            break
        case '/about':
            pageTitle='About'
            break
    }

    return (
        <AppHeader
            isDark
            appName={pageTitle}
            logoSlot={
                <Link to="/returns-overview" aria-label="logo-link">
                    <NikeApp
                        title="Nike logo"
                        color={'white'}
                        width={'56px'}
                        height={'64px'}
                        style={{ marginTop: '6px' }}
                    />
                </Link>
            }
            toolbarSlot={
                <nav aria-label="Main" style={{ paddingRight: 'var(--eds-space-4)' }}>
                    {
                        hasReturnsReadAccess(authState)
                        && <ToolbarLink link="/returns-overview" icon="Wallet"/>
                    }
                    {
                        isAplaRegion() && hasToleranceReadAccess(authState)
                        && extractBooleanFromEnvironmentVariable(process.env.REACT_APP_TOLERANCE_ENABLED)
                        && <ToolbarLink link="/tolerance-overview" icon="Data"/>
                    }
                    <ToolbarLink link="/about" icon="InfoCircle"/>
                    <GeoSelect />
                </nav>
            }
            avatarSlot={<Avatar initials={initials} size={36}/>}
        />
    )
}