import {Box, Card, Spinner} from '@nike/eds'
import { DetailsField } from '../../../shared/component/DetailsField'
import { date } from '../../../shared/datautils/formatter'
import { useQuery } from '@apollo/client'
import { geoConfig } from '../../../config/GeoConfig'
import { GetFinanceDetailUnitsDocument, GetInventoryDetailUnitsDocument } from '../../../generated/graphql'
import React from 'react'
import { DETAIL_TABS } from '../../constant/DetailTabEnum'

const STANDARD_COLUMN_WIDTH = '15%'
const FULL_WIDTH = '100%'
const FULL_HEIGHT = '100%'

export function S4ReturnsDetailCard ({detailUnitsState, returnSalesOrder}) {
    const {
        status,
        soldToCode,
        soldToName,
        shipToCode,
        shipToName,
        reasonCode,
        orderType,
        plantCode,
        receivedNodeDateTime,
        closureNodeDateTime,
        salesOrganisationCode,
        customerPurchaseOrderNumber,
        units,
        approvalDate,
        creditingOnProductLevel,
        returnSalesOrderNumber
    } = returnSalesOrder

    const {loading: isFetchingFinanceDetailUnits, data: financeDetailUnits} = useQuery(GetFinanceDetailUnitsDocument, {
        variables: {
            returnSalesOrderNumber: returnSalesOrderNumber,
            plantCode: plantCode,
            geoAllocation: geoConfig.getGeo()
        },
    })

    const {loading: isFetchingInventoryDetailUnits, data: inventoryDetailUnits} = useQuery(GetInventoryDetailUnitsDocument, {
        variables: {
            returnSalesOrderNumber: returnSalesOrderNumber,
            plantCode: plantCode,
            geoAllocation: geoConfig.getGeo()
        },
    })

    const financeDetailUnitsData = financeDetailUnits?.getFinanceDetailUnits
    const inventoryDetailUnitsData = inventoryDetailUnits?.getInventoryDetailUnits

    const isShowSpinner = () => {
        return (detailUnitsState === DETAIL_TABS.FINANCE && isFetchingFinanceDetailUnits) ||
            ((detailUnitsState === DETAIL_TABS.PRODUCT || detailUnitsState === DETAIL_TABS.ITEM) && isFetchingInventoryDetailUnits)
    }

    const isShowFinanceDetail = () => {
        return detailUnitsState === DETAIL_TABS.FINANCE && !isFetchingFinanceDetailUnits
    }

    const isShowInventoryDetail = () => {
        return (detailUnitsState === 'products' || detailUnitsState === 'items') && !isFetchingInventoryDetailUnits
    }

    return (
        <Box className="eds-grid eds-grid--m-cols-4 eds-grid--items-center" style={{ height: FULL_HEIGHT, width: FULL_WIDTH }}>
            <Card padding={12} className="eds-grid--col-start-1 eds-grid--col-end-4" style={{ height: FULL_HEIGHT, width: FULL_WIDTH }}>
                <p className="eds-spacing--py-8">
                    General info
                </p>
                <div className="flex-row flex-wrap col-span-2">
                    <DetailsField label="RSO status" style={{ width: STANDARD_COLUMN_WIDTH }}>{status}</DetailsField>
                    <DetailsField label="Expected units" style={{ width: STANDARD_COLUMN_WIDTH }}>{units.expectedUnits}</DetailsField>
                    <DetailsField label="Received units" style={{ width: STANDARD_COLUMN_WIDTH }}>{units.receivedUnits}</DetailsField>
                    <DetailsField label="Creaditable units" style={{ width: STANDARD_COLUMN_WIDTH }}>{units.creditableUnits}</DetailsField>
                    <DetailsField label="Non creaditable units" style={{ width: STANDARD_COLUMN_WIDTH }}>{units.nonCreditableUnits}</DetailsField>
                    <DetailsField label="Total A-grade" style={{ width: STANDARD_COLUMN_WIDTH }}>{units.totalAGradeUnits}</DetailsField>
                    <DetailsField label="Total B-grade" style={{ width: STANDARD_COLUMN_WIDTH }}>{units.totalBGradeUnits}</DetailsField>
                    <DetailsField label="Total C-grade" style={{ width: STANDARD_COLUMN_WIDTH }}>{units.totalCGradeUnits}</DetailsField>
                    <DetailsField label="Crediting level" style={{ width: STANDARD_COLUMN_WIDTH }}>
                        {creditingOnProductLevel ? 'Product' : 'Item'}
                    </DetailsField>
                    <DetailsField label="Sold to" style={{ width: STANDARD_COLUMN_WIDTH }}>
                        {soldToCode}{soldToName ? ' - ' + soldToName : ''}
                    </DetailsField>
                    <DetailsField label="Ship to" style={{ width: STANDARD_COLUMN_WIDTH }}>
                        {shipToCode}{shipToName ? ' - ' + shipToName : ''}
                    </DetailsField>
                    <DetailsField label="Order type" style={{ width: STANDARD_COLUMN_WIDTH }}>{orderType}</DetailsField>
                    <DetailsField label="Reason code" style={{ width: STANDARD_COLUMN_WIDTH }}>{reasonCode}</DetailsField>
                    <DetailsField label="Sales organisation code" style={{ width: STANDARD_COLUMN_WIDTH }}>{salesOrganisationCode}</DetailsField>
                    <DetailsField label="Plant code" style={{ width: STANDARD_COLUMN_WIDTH }}>{plantCode}</DetailsField>
                    <DetailsField label="Approval date" style={{ width: STANDARD_COLUMN_WIDTH }}>
                        {approvalDate ? date(approvalDate) : 'N/A'}
                    </DetailsField>
                    <DetailsField label="Received datetime" style={{ width: STANDARD_COLUMN_WIDTH }}>
                        {receivedNodeDateTime ? date(receivedNodeDateTime, true) : 'N/A'}
                    </DetailsField>
                    <DetailsField label="Closure datetime" style={{ width: STANDARD_COLUMN_WIDTH }}>
                        {closureNodeDateTime ? date(closureNodeDateTime, true) : 'N/A'}
                    </DetailsField>
                    <DetailsField label="Purchase order number" style={{ width: STANDARD_COLUMN_WIDTH }}>{customerPurchaseOrderNumber}</DetailsField>
                </div>
            </Card>
            <Card padding={12} className="eds-grid--col-start-4" style={{ height: FULL_HEIGHT, width: FULL_WIDTH }}>
                <p className="eds-spacing--py-8">
                    {
                        detailUnitsState === DETAIL_TABS.FINANCE &&
                        'Returns Finance details'
                    }
                    {
                        (detailUnitsState === DETAIL_TABS.PRODUCT || detailUnitsState === DETAIL_TABS.ITEM) &&
                        'Returns Inventory details'
                    }
                </p>
                <div className="flex-row flex-wrap">
                    <DetailsField label="Expected & Received A-grade" style={{width: FULL_WIDTH}}>
                        { isShowSpinner() ?  <Spinner /> : '' }
                        {
                            isShowFinanceDetail() ? financeDetailUnitsData.expectedAndReceivedNonCGradeUnits : ''
                        }
                        {
                            isShowInventoryDetail() ? inventoryDetailUnitsData.expectedAndReceivedNonCGradeUnits : ''
                        }
                    </DetailsField>
                    <DetailsField label="Unexpected & Overage units" style={{width: FULL_WIDTH}}>
                        { isShowSpinner() ?  <Spinner /> : '' }
                        {
                            isShowFinanceDetail() ? financeDetailUnitsData.unexpectedAndOverageUnits : ''
                        }
                        {
                            isShowInventoryDetail() ? inventoryDetailUnitsData.unexpectedAndOverageUnits : ''
                        }
                    </DetailsField>
                    <DetailsField label="Shortage units" style={{width: FULL_WIDTH}}>
                        { isShowSpinner() ?  <Spinner /> : '' }
                        {
                            isShowFinanceDetail() ? financeDetailUnitsData.shortageUnits : ''
                        }
                        {
                            isShowInventoryDetail() ? inventoryDetailUnitsData.shortageUnits : ''
                        }
                    </DetailsField>
                </div>
            </Card>
        </Box>
    )
}
