import { TabGroup, Tab } from '@nike/eds'
import React, { useState } from 'react'
import { detailTabConfig } from './DetailTabConfig'
import { DETAIL_TABS } from '../constant/DetailTabEnum'
import { AfsFinanceDetailProductTable } from './afs/AfsFinanceDetailProductTable'
import { AfsFinanceDetailItemTable } from './afs/AfsFinanceDetailItemTable'
import { AfsInventoryDetailItemTable } from './afs/AfsInventoryDetailItemTable'
import { AfsInventoryDetailProductTable } from './afs/AfsInventoryDetailProductTable'
import { S4FinanceDetailProductTable } from './s4/S4FinanceDetailProductTable'
import { S4FinanceDetailItemTable } from './s4/S4FinanceDetailItemTable'
import { S4InventoryDetailItemTable } from './s4/S4InventoryDetailItemTable'
import { showAfsView, showS4View } from '../../shared/datautils/regionView'

export function ReturnsDetailTabGroup({returnSalesOrderNumber, plantCode, creditingOnProductLevel, sapVersion, updateDetailUnits}) {
    const [activeId, setActiveId] = useState(detailTabConfig.getDetailTab())

    const handleChange = (event) => {
        setActiveId(event.target.id)
        detailTabConfig.updateDetailTab(event.target.id)
        updateDetailUnits(event.target.id)
    }

    return (
        <div>
            <TabGroup
                activeId={activeId}
                name="tab-button-group"
                onChange={handleChange}
                className="eds-spacing--ml-12 eds-spacing--mt-12 eds-spacing--mb-12"
            >
                <Tab id={DETAIL_TABS.FINANCE}>
                    Creditable/non-creditable Inventory
                </Tab>
                {
                    showAfsView(sapVersion) &&
                    <Tab id={DETAIL_TABS.PRODUCT}>
                        Products
                    </Tab>
                }
                <Tab id={DETAIL_TABS.ITEM}>
                    Items
                </Tab>
            </TabGroup>

            {/* Creditable/non-creditable Inventory tab - product level */}
            {
                showAfsView(sapVersion) && activeId === DETAIL_TABS.FINANCE && creditingOnProductLevel &&
                <AfsFinanceDetailProductTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }
            {
                showS4View(sapVersion) && activeId === DETAIL_TABS.FINANCE && creditingOnProductLevel &&
                <S4FinanceDetailProductTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }

            {/* Creditable/non-creditable Inventory tab - item level */}
            {
                showAfsView(sapVersion) && activeId === DETAIL_TABS.FINANCE && !creditingOnProductLevel &&
                <AfsFinanceDetailItemTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }
            {
                showS4View(sapVersion) && activeId === DETAIL_TABS.FINANCE && !creditingOnProductLevel &&
                <S4FinanceDetailItemTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }

            {/* Product tab */}
            {
                showAfsView(sapVersion) && activeId === DETAIL_TABS.PRODUCT &&
                <AfsInventoryDetailProductTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }

            {/* Item tab */}
            {
                showAfsView(sapVersion) && activeId === DETAIL_TABS.ITEM &&
                <AfsInventoryDetailItemTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }
            {
                showS4View(sapVersion) && activeId === DETAIL_TABS.ITEM &&
                <S4InventoryDetailItemTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }
        </div>
    )
}
