import { useMemo } from 'react'
import { TableLayout, useServerDataTable } from '../../../shared/table'
import { TableHeaderWithTooltip } from '../../../shared/component/TableHeaderWithTooltip'
import useQueryState from '../../../shared/hooks/useQueryState'
import { useQuery } from '@apollo/client'
import { GetFinanceReturnSalesOrderProductItemsDocument } from '../../../generated/graphql'
import { ApolloResult } from '../../../shared/component/ApolloResult'
import { geoConfig } from '../../../config/GeoConfig'

const useEmeaFinanceDetailItemTable = (queryState,
                                          gotoPage,
                                          setPageSize,
                                          toggleSortBy,
                                          isSuccess,
                                          data) => {

    const columns = useMemo(() => [
        {
            Header: () => <div>Product id</div>,
            accessor: 'productId',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <div>Size</div>,
            accessor: 'size',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <div>Status</div>,
            accessor: 'status',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Expected " toolTipContent="Total amount of expected units (coming from the RSO creation information)"/>,
            accessor: 'productUnits.expectedUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Received " toolTipContent="Total amount of all grade units retrieved in the Node" />,
            accessor: 'productUnits.receivedUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Creditable" toolTipContent="Total amount of creditable units" />,
            accessor: 'productUnits.creditableUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Non creditable" toolTipContent="Total amount of non creditable units" />,
            accessor: 'productUnits.nonCreditableUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Total A-grade" toolTipContent="Total amount of A-grade units retrieved in the Node. Overages and unexpected units are included" />,
            accessor: 'productUnits.totalAGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Total B-Grade" toolTipContent="Total amount of B-grade units retrieved in the Node. Overages and unexpected units are included"/>,
            accessor: 'productUnits.totalBGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Total C-Grade" toolTipContent="Total amount of C-grade units retrieved in the Node. Overages and unexpected units are included"/>,
            accessor: 'productUnits.totalCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Expected A-grade" toolTipContent="Amount of expected A-grade units that are retrieved in the Node"/>,
            accessor: 'productUnits.expectedAndReceivedNonCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Expected B-grade" toolTipContent="Amount of expected B-grade units that are retrieved in the Node"/>,
            accessor: 'productUnits.expectedBGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Expected C-grade" toolTipContent="Amount of expected C-grade units that are retrieved in the Node"/>,
            accessor: 'productUnits.expectedCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Overage A-grade" toolTipContent="Amount of unexpected and overage A-grade units" /> ,
            accessor: 'productUnits.unexpectedAndOverageAGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Overage B-grade" toolTipContent="Amount of unexpected and overage B-grade units" />,
            accessor: 'productUnits.unexpectedAndOverageBGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Overage C-grade" toolTipContent="Amount of unexpected and overage C-grade units" />,
            accessor: 'productUnits.unexpectedAndOverageCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Shortage" toolTipContent="Total amount of A-grade units that were expected but not retrieved in the Node"/>,
            accessor: 'productUnits.shortageUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Missing" toolTipContent="Total amount of all grade units that were expected but not retrieved in the Node" />,
            accessor: 'productUnits.missingUnits',
            Cell: item => <div>{item.value}</div>
        },

    ], [])

    return useServerDataTable({
        queryState,
        overrides: {
            gotoPage,
            setPageSize,
            toggleSortBy,
        },
        columns,
        isSuccess,
        data,
    })
}

export function S4FinanceDetailItemTable({returnSalesOrderNumber, plantCode}) {
    const {
        state: queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
    } = useQueryState<null>()

    const {loading: isFetching, error, data, networkStatus, } = useQuery(GetFinanceReturnSalesOrderProductItemsDocument, {
        variables: {
            productsViewInput: {
                returnSalesOrderNumber,
                plantCode,
                from: queryState.pageNumber * queryState.pageSize,
                size: queryState.pageSize,
                sorting: queryState.sortBy,
            },
            geoAllocation: geoConfig.getGeo()
        }
    })

    const tableInstance = useEmeaFinanceDetailItemTable(
        queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
        !error && !isFetching,
        {
            data: data ? data.getFinanceReturnSalesOrderProductItems.productItems : [],
            ...data?.getFinanceReturnSalesOrderProductItems.paging
        }
    );

    return (
        <ApolloResult networkStatus={networkStatus} error={error} loadingDataName="items">
            <TableLayout {...tableInstance} isFetching={isFetching} loadingDataName="items"/>
        </ApolloResult>
    )
}
