import {useMemo} from 'react'
import {TableLayout, useServerDataTable} from '../../../shared/table'
import {TableHeaderWithTooltip} from '../../../shared/component/TableHeaderWithTooltip'
import useQueryState from '../../../shared/hooks/useQueryState'
import {useQuery} from '@apollo/client'
import {GetReturnSalesOrderProductsDocument} from '../../../generated/graphql'
import {ApolloResult} from '../../../shared/component/ApolloResult'
import {geoConfig} from '../../../config/GeoConfig'

const useAplaInventoryDetailProductTable = (queryState,
                                  gotoPage,
                                  setPageSize,
                                  toggleSortBy,
                                  isSuccess,
                                  data) => {
    const columns = useMemo(() => [
        {
            Header: () => <div>Product id</div>,
            accessor: 'productId',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <div>Status</div>,
            accessor: 'status',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Expected units"
                                                  toolTipContent="Total amount of expected units (coming from the RSO creation information)"/>,
            accessor: 'productUnits.expectedUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Received units"
                                                  toolTipContent="Total amount of units retrieved in the Node. The C-graded units are included"/>,
            accessor: 'productUnits.receivedUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Expected & Received non C-grade units"
                                                  toolTipContent="Amount of units retrieved in the Node that were expected from the RSO creation. The C-graded units are excluded"/>,
            accessor: 'productUnits.expectedAndReceivedNonCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Total C-Grade"
                                                  toolTipContent="Total amount of C-grade units. Overages and unexpected units are included"/>,
            accessor: 'productUnits.totalCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Shortage units"
                                                  toolTipContent="Total amount of units that were expected but not retrieved in the Node. The C-graded units are included"/>,
            accessor: 'productUnits.shortageUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Expected C-grade units"
                                                  toolTipContent="Amount of expected units that are retrieved in the Node but are C-grade"/>,
            accessor: 'productUnits.expectedCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Missing units"
                                                  toolTipContent="Amount of expected units that are not retrieved in the Node"/>,
            accessor: 'productUnits.missingUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Unexpected & Overage units"
                                                  toolTipContent="Total amount of unexpected and overage Units. The C-graded units are included"/>,
            accessor: 'productUnits.unexpectedAndOverageUnits',
            Cell: item => <div>{item.value}</div>
        },
        {
            Header: () => <TableHeaderWithTooltip headerContent="Unexpected & Overage C-grade"
                                                  toolTipContent="Amount of unexpected and overage Units that are C-graded"/>,
            accessor: 'productUnits.unexpectedAndOverageCGradeUnits',
            Cell: item => <div>{item.value}</div>
        },
    ], [])

    return useServerDataTable({
        queryState,
        overrides: {
            gotoPage,
            setPageSize,
            toggleSortBy,
        },
        columns,
        isSuccess,
        data,
    })
}

export interface InventoryDetailProductTableProps {
    returnSalesOrderNumber: String
    plantCode: String
}

export function AfsInventoryDetailProductTable({returnSalesOrderNumber, plantCode}: InventoryDetailProductTableProps) {

    const {
        state: queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
    } = useQueryState<any>()

    const {loading: isFetching, error, data, networkStatus, } = useQuery(GetReturnSalesOrderProductsDocument, {
        variables: {
            productsViewInput: {
                returnSalesOrderNumber,
                plantCode,
                from: queryState.pageNumber * queryState.pageSize,
                size: queryState.pageSize,
                sorting: queryState.sortBy,
            },
            geoAllocation: geoConfig.getGeo()
        }
    })

    const tableInstance = useAplaInventoryDetailProductTable(
        queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
        !error && !isFetching,
        {
            data: data ? data.getReturnSalesOrderProducts.products : [],
            ...data?.getReturnSalesOrderProducts?.paging
        }
    )

    return (
        <ApolloResult networkStatus={networkStatus} error={error} loadingDataName="products">
            <TableLayout {...tableInstance} isFetching={isFetching} loadingDataName="products"/>
        </ApolloResult>
    )
}
