
export const generateReturnDetailLink = (item) => {
    let rsoNumber = encodeURIComponent(item.value);
    let plantCode = encodeURIComponent(item?.row?.original?.plantCode);
    return `/returns-detail/${rsoNumber}?plantCode=${plantCode}`;
}

export interface FinanceOverviewTableProps {
    queryState: any,
    gotoPage: (pageNumber) => void,
    setPageSize: (pageSize) => void,
    toggleSortBy: (id, sortByDesc) => void,
    clearFilter: () => void,
    isFetching: boolean,
    isSuccess: boolean,
    data: object,
    totalResources?: number,
}
