import { date } from '../../shared/datautils/formatter'
import { Button } from '@nike/eds'
import Chips from '../../shared/layout/Chips'
import { FinanceOverviewFilterFields } from '../../model/FinanceOverviewModels'
import { ReturnsDownloadComponent } from '../download/ReturnsDownloadComponent'
import { returnsOverviewFilterStore } from './ReturnsOverviewFilterStore'

const makeChipsArray = (filter: FinanceOverviewFilterFields = {}) => {
    const chipsArrayFormat = (field: string, value: any) => {
        switch (field) {
            case 'returnSalesOrderNumbers':
                return `RSO number: ${value}`
            case 'soldToCustomers':
                return `Sold to customer: ${value}`
            case 'shipToCustomers':
                return `Ship to customer: ${value}`
            case 'salesOrganisationCodes':
                return `Sales organisation code: ${value}`
            case 'approvalDateFrom':
                return `Approval Date from: ${date(value)}`
            case 'approvalDateTo':
                return `Approval Date to: ${date(value)}`
            case 'receivedDateTimeFrom':
                return `RDT from: ${date(value)}`
            case 'receivedDateTimeTo':
                return `RDT to: ${date(value)}`
            case 'plantCodes':
                return `Plant code: ${value}`
            case 'orderTypes':
                return `Order type: ${value}`
            case 'reasonCodes':
                return `Reason code: ${value}`
            case 'customerType':
                return `Customer tolerance: ${value ? 'FULL' : 'NONE'}`
            case 'status':
                return `RSO status: ${value}`
            default:
                return value
        }
    }
    return Object.keys(filter)
        .flatMap(field => {
            const filterValue = filter[field]
            if (Array.isArray(filterValue)) {
                return filterValue.map((value, index) => ({
                    field,
                    value,
                    index,
                    stringValue: chipsArrayFormat(field, value),
                }))
            }
            return [{ field, value: filterValue, stringValue: chipsArrayFormat(field, filterValue) }]
        })
        .filter(it => it.value !== undefined)
}

export interface FinanceOverviewFilterChipsProps {
    filter: FinanceOverviewFilterFields
    onChipDelete: any
    clearFilter: () => void,
    expandFilterForm: (expandFilter) => void,
}

export function ReturnsOverviewFilterChips({filter, onChipDelete, clearFilter, expandFilterForm}:FinanceOverviewFilterChipsProps) {
    const chipsArray = makeChipsArray(filter)

     const financeOverviewClearFilter = () => {
        clearFilter()
        returnsOverviewFilterStore.clearStorage()
    }

    return (

        <div
            className='flex-row flex-nowrap content-space-between justify-items-start'
            style={{ padding: 'var(--eds-space-16) var(--eds-space-16) 0 var(--eds-space-16)' }}
        >
            <div
                style={{
                    width: '90%',
                }}
            >
                <Chips allValues={chipsArray} onDelete={onChipDelete} clearFilter={financeOverviewClearFilter} />
            </div>

            <ReturnsDownloadComponent />

            <div className='flex-shrink-0'>
                <Button
                    className='popover-filter'
                    style={{ marginTop: 'var(--eds-space-8)' }}
                    onClick={() => expandFilterForm(true)}
                >
                    Edit Filters
                </Button>
            </div>
        </div>
    )
}