import { FinanceOverviewFilterFields } from '../../model/FinanceOverviewModels'

export const mapStringToArrayOrUndefined = (value) =>
    value ? value.trim().split(/[\s:,;\n]+/).map(s => s.trim()) : undefined

export interface FinanceOverviewFilterProps {
    currentFilter: FinanceOverviewFilterFields,
    onClickFilter: (formData: FinanceOverviewFilterFields) => void,
    expand: boolean,
    onCloseSidePanel: () => void
}
