import { geoConfig } from '../../config/GeoConfig'
import { DETAIL_TABS } from '../constant/DetailTabEnum'

export const detailTabConfig = {
    detailTabStorageKey: 'detailTab' + geoConfig.getGeo(),
    updateDetailTab: (detailTab) => {
        localStorage.setItem(detailTabConfig.detailTabStorageKey, detailTab)
    },
    getDetailTab: () => {
        return localStorage.getItem(detailTabConfig.detailTabStorageKey) || DETAIL_TABS.FINANCE
    },
}
